@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    /** notice that ie has only a single colon) */
    color: $color;
  }

  &::-webkit-input-placeholder {
    color: $color;
  }

  &::placeholder {
    color: $color;
  }
}

.do-w3__input {
  border: 2px solid var(--clr-accent-lg);
  padding: 1rem;

  background: transparent;

  -webkit-transition: 0.2s;
  transition: 0.2s;

  &:focus, &:focus-visible, &:active {
    outline: var(--clr-secondary-y) solid 2px;

    @include placeholder(transparent);
  }

  @include placeholder(var(--clr-secondary-w));
}
