.exp__carousel {
  @media only screen and (min-width: 50.01rem) {
    display: none;
  }
}

.exp__default {
  @media only screen and (max-width: 50.01rem) {
    display: none;
  }
}
