$border_w: 0.5rem;
$_2brw: 2 * $border_w;
$_3brw: 3 * $border_w;
$_4brw: 4 * $border_w;
$_5brw: 5 * $border_w;

.carousel-left-arrow {
  min-width: $_5brw;
  height: $_5brw;

  margin-left: -$_2brw;

  clip-path: polygon($_2brw $_2brw, $_3brw $_2brw, $_3brw $border_w, $_4brw $border_w, $_4brw 0, 100% 0, 100% 100%, $_4brw 100%, $_4brw $_4brw, $_3brw $_4brw, $_3brw $_3brw, $_2brw $_3brw);
}

.carousel-right-arrow {
  min-width: $_5brw;
  height: $_5brw;

  margin-right: -$_2brw;

  clip-path: polygon(0 0, $border_w 0, $border_w $border_w, $_2brw $border_w, $_2brw $_2brw, $_3brw $_2brw, $_3brw $_3brw, $_2brw $_3brw, $_2brw $_4brw, $border_w $_4brw, $border_w 100%, 0 100%);
}

.carousel-flex-gap {
  gap: var(--fs-txt);
}
