.do-web3__wrap {
  --gap: 0;

  @media only screen and (max-width: 50.5rem) {
    flex-direction: column;
  }
}

.do-web3-gap {
  --gap: 1.25em;
}

.reg-int__cont {
  padding: 2em 2em;
  padding-bottom: 3em;
}

#register-interest {
  margin-bottom: calc(4 * var(--fs-subttl-2));
}

.do-web3__thx-icon {
  width: 20%;
}

.do-web3__flex-just-center {
  justify-content: center;
}
