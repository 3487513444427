@mixin social_icon($icon_path) {
  max-height: 32px;
  width: 32px;
  aspect-ratio: 1;
  background: center url($icon_path) no-repeat;
  border-radius: 4px;
  background-color: var(--clr-primary);

  &:hover {
    background-color: var(--clr-secondary-m);
  }
}

.social_icon--discord {
  @include social_icon('./discord-icon.svg');
}

.social_icon--twitter {
  @include social_icon('./twitter-icon.svg');
}

.social_icon--telegram{
  @include social_icon('./telegram-icon.svg');
}

.socials {
  margin: 1.25rem 0;
}
