$border_w: 0.2rem;
$_2bw: 2 * $border_w;
$_100_min_2bw: calc(100% - $_2bw);
$_100_min_bw: calc(100% - $border_w);

.button {
  padding: $border_w;

  clip-path: polygon(0 $_2bw, $border_w $_2bw, $border_w $border_w, $_2bw $border_w, $_2bw 0, $_100_min_2bw 0, $_100_min_2bw $border_w, $_100_min_bw $border_w, $_100_min_bw $_2bw, 100% $_2bw, 100% 100%, 0 100%);

  &:active {
    margin-top: $_2bw;
    height: auto;
    clip-path: none;

    .button__inner {
      clip-path: none;
      padding-bottom: 0;
    }
  }
}

.button__inner {
  padding-bottom: $_2bw;

  clip-path: polygon(0 $_2bw, $border_w $_2bw, $border_w $border_w, $_2bw $border_w, $_2bw 0%, $_100_min_2bw 0, $_100_min_2bw $border_w, $_100_min_bw $border_w, $_100_min_bw $_2bw, 100% $_2bw, 100% 100%, $_100_min_bw 100%, $_100_min_bw $_100_min_bw, $_100_min_2bw $_100_min_bw, $_100_min_2bw $_100_min_2bw, $_2bw $_100_min_2bw, $_2bw $_100_min_bw, $border_w $_100_min_bw, $border_w 100%, 0 100%);

  align-items: center;
  justify-content: center;

  &:active {
    clip-path: none;
    padding-bottom: 0;
  }
}

.button__inner-container {
  padding: 0.7rem 1rem;
}
