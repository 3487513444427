.carousel-dot {
  --dot-size: var(--fs-subttl-1);
  --border-w: calc(var(--dot-size) / 6);
  --tr-corner-size: var(--border-w);

  width: var(--dot-size);
  height: var(--dot-size);

  padding: var(--border-w);
}

.carousel-dot--default {
  background-color: var(--clr-secondary-m);

  &:hover {
    background-color: var(--clr-accent-c);
  }
}

.carousel-dot--selected {
  background-color: var(--clr-secondary-y);

  &:hover {
    background-color: var(--clr-secondary-m);
  }
}

.carousel-dot--clip-path {
  clip-path: polygon(0% var(--tr-corner-size), var(--tr-corner-size) var(--tr-corner-size), var(--tr-corner-size) 0%, /*top left*/ calc(100% - var(--tr-corner-size)) 0, calc(100% - var(--tr-corner-size)) var(--tr-corner-size), 100% var(--tr-corner-size), /*top right*/ 100% calc(100% - var(--tr-corner-size)), calc(100% - var(--tr-corner-size)) calc(100% - var(--tr-corner-size)), calc(100% - var(--tr-corner-size)) /*bot right*/ 100%, var(--tr-corner-size) 100%, var(--tr-corner-size) calc(100% - var(--tr-corner-size)), 0% calc(100% - var(--tr-corner-size)) /*bot left*/ );
}
//
// $tr_sbw: calc(var(--tr-corner-size) + $border_w);
// $_100_min_corn: calc(100% - var(--tr-corner-size));
// $_2corn: calc(2 * var(--tr-corner-size));
// $_100_min__2corn: calc(100% - $_2corn);
//
// .carousel-dot--border-clip-path {
//   clip-path: polygon(0 var(--tr-corner-size), var(--tr-corner-size) var(--tr-corner-size), var(--tr-corner-size) 0, $_100_min_corn 0, $_100_min_corn var(--tr-corner-size), 100% var(--tr-corner-size), 100% $_100_min_corn, $_100_min_corn $_100_min_corn, $_100_min_corn 100%, var(--tr-corner-size) 100%, var(--tr-corner-size) $_100_min_corn, 0 $_100_min_corn, 0 var(--tr-corner-size), var(--tr-corner-size) var(--tr-corner-size), var(--tr-corner-size) $_100_min__2corn, $_2corn $_100_min__2corn, $_2corn $_100_min_corn, $_100_min__2corn $_100_min_corn, $_100_min__2corn $_100_min__2corn, $_100_min_corn $_100_min__2corn, $_100_min_corn $_2corn, $_100_min__2corn $_2corn, $_100_min__2corn var(--tr-corner-size), $_2corn var(--tr-corner-size), $_2corn $_2corn, 0 $_2corn);
// }
