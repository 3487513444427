.separator--clip-path {
  $bw: 0.25rem;

  height: $bw;
  min-height: $bw;
  $space: $bw;
  $_2space: calc(2 * $space);

  $dot_11: calc(0% + $bw);
  $dot_11_space: calc($dot_11 + $_2space);

  $dot_12: calc($dot_11_space + $bw);
  $dot_12_space: calc($dot_12 + $_2space);

  $dot_21: calc($dot_12_space + $bw);
  $dot_21_space: calc($dot_21 + $space);

  $dot_22: calc($dot_21_space + $bw);
  $dot_22_space: calc($dot_22 + $space);

  $dot_221: calc($dot_22_space + $bw);
  $dot_221_space: calc($dot_221 + $space);

  $dot_222: calc($dot_221_space + $bw);
  $dot_222_space: calc($dot_222 + $space);

  $line_w: calc(100% - 2 * (6 * $bw + 4 * $space + 2 * $_2space));

  $line: calc($dot_222_space + $line_w);
  $line_space: calc($line + $space);

  $dot_231: calc($line_space + $bw);
  $dot_231_space: calc($dot_231 + $space);
  $dot_232: calc($dot_231_space + $bw);
  $dot_232_space: calc($dot_232 + $space);

  $dot_23: calc($dot_232_space + $bw);
  $dot_23_space: calc($dot_23 + $space);

  $dot_24: calc($dot_23_space + $bw);
  $dot_24_space: calc($dot_24 + $_2space);

  $dot_13: calc($dot_24_space + $bw);
  $dot_13_space: calc($dot_13 + $_2space);

  clip-path: polygon(
  0% 0%, $dot_11 0%,
  $dot_11 100%, $dot_11_space 100%,
  $dot_11_space 0%, $dot_12 0%,
  $dot_12 100%, $dot_12_space 100%,
  $dot_12_space 0%, $dot_21 0%,
  $dot_21 100%, $dot_21_space 100%,
  $dot_21_space 0%, $dot_22 0%,
  $dot_22 100%, $dot_22_space 100%,
  $dot_22_space 0%, $dot_221 0%,
  $dot_221 100%, $dot_221_space 100%,
  $dot_221_space 0%, $dot_222 0%,
  $dot_222 100%, $dot_222_space 100%,
  $dot_222_space 0%, $line 0%,
  $line 100%, $line_space 100%,
  $line_space 0%, $dot_231 0%,
  $dot_231 100%, $dot_231_space 100%,
  $dot_231_space 0%, $dot_232 0%,
  $dot_232 100%, $dot_232_space 100%,
  $dot_232_space 0%, $dot_23 0%,
  $dot_23 100%, $dot_23_space 100%,
  $dot_23_space 0%, $dot_24 0%,
  $dot_24 100%, $dot_24_space 100%,
  $dot_24_space 0%, $dot_13 0%,
  $dot_13 100%, $dot_13_space 100%,
  $dot_13_space 0%, 100% 0%,
  100% 100%, 0% 100%
  );
}
