.play-now-container {
  flex-direction: column;
  justify-content: flex-end;

  margin-bottom: calc(var(--be-first-mar-top-abs) - 1rem);

  width: 100%;

  @media only screen and (min-width: 30rem) {
    width: 60%;
  }

  @media only screen and (min-width: 64rem) {
    width: 33%;
  }
}

.play-now-button {
  --button-min-height: 2.5rem;

  width: 100%;
  min-height: var(--button-min-height);

  @media only screen and (min-width: 30rem) {
    --button-min-height: 3.5rem;
  }

  @media only screen and (min-width: 64rem) {
    --button-min-height: 5rem;
  }
}

.play-now-section__text {
  text-shadow: 0 8px 0 var(--clr-primary);
  margin: 0;

  @media only screen and (min-width: 30rem) {
    text-align: center;
  }

  @media only screen and (min-width: 64rem) {
    text-align: left;
    margin-left: 0;
    margin-right: -20rem;
  }
}

.play-now-section__image {
  width: 100%;
}

.play-now__bg-img {
  max-width: 600%;
}

.play-now-section {
  width: 100%;
  height: 470px;

  --coin-s: url('./Coin_s.png');
  --coin-m: url('./Coin_m.png');
  --coin: url('./Coin.png');

  --char-s: url('./Char_s.png');
  --char-m: url('./Char_m.png');
  --char: url('./Char.png');

  --snake-m: url('./cover_snake_m.png');
  --snake: url('./cover_snake.png');

  --rect: url('./Rectangle 161.png');

  background: var(--rect) center no-repeat;
  background-size: cover;

  @media only screen and (min-width: 30rem) {
    height: 380px;
  }

  @media only screen and (min-width: 64rem) {
    height: 640px;
  }
}

.play-now-section__bg-layout {
  background: var(--coin-s) calc(70px + 70%) 20% no-repeat, var(--char-s) calc(60% - 70px) center no-repeat;

  @media only screen and (min-width: 30rem) {
    background: var(--coin-m) calc(55% - 60px) 20px no-repeat, var(--char-m) calc(50% - 250px) 100% no-repeat, var(--snake-m) calc(50% - 50px) 50px no-repeat;
  }

  @media only screen and (min-width: 64rem) {
    background: var(--coin) calc(45% - 80px) 10% no-repeat, var(--char) calc(45% - 350px) 100% no-repeat, var(--snake) 50% 100px no-repeat;
  }
}

.flex--just-cnt-cent {
  justify-content: flex-start;

  @media only screen and (min-width: 30rem) {
    justify-content: center;
  }
}
