.lg-game__container--flx {
  display: flex;
  align-items: center;
  width: 100%;
}

.lg-game__text {
  margin-bottom: 1.75rem; 
}

.lg-game__image {
  margin-left: 0.3rem;
  margin-right: 0.4rem;

  max-height: var(--fs-subttl-1);
}

.lg-game__button {
  border: 2px solid #A6A6A6;
  border-radius: var(--fs-cpt);
}

.svg-button--apple-google{
  position: relative;
  width: min(90%, 25rem);
  aspect-ratio: 5;
  svg {
    fill: var(--clr-secondary-w);
  }
  &:hover {
    svg {
      fill: var(--clr-primary);
    }
    background-color: var(--clr-secondary-y);
    border-color: var(--clr-secondary-y);
  }
}

.lg-game-container {
  flex-direction: column;

  gap: calc(1.7 * var(--fs-ttl-1));

  @media only screen and (min-width: 64rem) {
    flex-direction: row;
  }
}

.lg-game__buttons {
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 40rem) {
    flex-direction: row;
  }

  @media only screen and (min-width: 64rem) {
    flex-direction: column;
  }
}
