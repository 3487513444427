.download-section__text-container {
  display: grid;

  grid-template-columns: 1fr;

  @media only screen and (min-width: 40rem) {
    grid-template-columns: 1fr 1fr;
  }
}

.download-section__text-row-container {
  --gap: 0.5em;

  justify-content: space-between;

  @media only screen and (min-width: 40rem) {
    justify-content: flex-start;
  }
}

.ds--gap {
  --gap: var(--fs-ttl-2);
}
