$header__h: 3.5rem;
$header__h--t: 4rem;
$header__h--d: 7rem;

.header--sticky {
  --header--menu-cross-img: url('./icon_menu_close.png');
  --header--menu-hamb-img: url('./icon_menu.png');

  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;

  height: $header__h;

  @media only screen and (min-width: 30rem) {
    height: $header__h--t;
  }
  @media only screen and (min-width: 50rem) {
    height: $header__h--d;
  }

  box-shadow: 0 0.4rem rgb(0 0 0 / 0.3);
}

.height-inherit {
  height: inherit;
}

.header-container {
}

$header__logo__h: 1.75rem;

.header__logo {
  height: 60%;
  margin: auto 0;
}

.mobile-nav-toggle {
  display: none;
}

.header-nav-ul {
  .header-nav__a {
    font-size: var(--fs-nav);
    text-transform: uppercase;
    color: var(--clr-secondary-y);

    &:hover {
      color: var(--clr-secondary-m);
    }
  }
}

$menu_top_padding: 2rem;

@media only screen and (max-width: 50rem) {
  .header-nav {
    width: 1px;
  }
}

@media only screen and (max-width: 50rem) {
  .header-nav-ul {
    box-shadow: inset 0 0.4rem rgb(0 0 0 / 0.3);

    top: $header__h;
    @media only screen and (min-width: 30rem) {
      top: $header__h--t;
    }
    
    left: 0;
    position: absolute;

    flex-direction: column;
    min-width: 100%;

    z-index: 500;

    padding: $menu_top_padding 0;

    background: transparent;

    transform: translateY(-120%);
    // transition: transform 350ms ease-in;

    .header-nav__a {
      color: transparent;
    }
  }

  .header-nav-ul[data-visible="true"] {
    transform: translateY(0%);
    background-color: var(--clr-secondary-y);

    .header-nav__a {
      color: var(--clr-primary);

      &:hover {
        color: var(--clr-secondary-m);
        text-decoration: none;
      }
    }
  }

  .mobile-nav-toggle {
    display: block;
    position: relative;
    background-image: var(--header--menu-hamb-img);

    width: 1.5rem;
    aspect-ratio: 1;
    z-index: 1010;
  }

  .mobile-nav-toggle[aria-expanded="true"] {
    background-image: var(--header--menu-cross-img);
  }
}

.center {
  margin: 0 auto;
}
