@font-face {
  font-family: "sb_standard";
  src: local("sb_standard_bold"), url("./fonts/sb_standard_bold-webfont.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: "sb_standard";
  src: local("sb_standard_regular"), url("./fonts/sb_standard_regular-webfont.woff2") format("woff2");
  font-weight: normal;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;

  font-family: var(--ff-primary);

  overflow-x: hidden;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  &:hover,
  &:link,
  &:visited,
  &:active { text-decoration: none; }
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media only screen and (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

$tablet_min: 30rem;
$desktop_min: 64rem;

:root {
  --clr-primary: black;
  --clr-secondary-w: white;
  --clr-secondary-y: hsl(58, 100%, 50%); // yellow
  --clr-secondary-m: hsl(290, 100%, 50%); // magenta
  --clr-accent-g:	hsl(137, 100%, 31%); // green
  --clr-accent-lg: hsl(137, 79.4%, 50.6%); // light green
  --clr-accent-c: hsl(169, 100%, 50%); // cyan
  --clr-accent-lc: hsl(177, 100%, 66%); // light cyan
  --clr-accent-o: hsl(18, 88%, 53%); // orange

  --ff-primary: "sb_standard";

  --fw-regular: 500;
  --fw-bold: 700;

  --tablet-min: $tablet_min;
  --desktop-min: $desktop_min;

  // mobile
  --fs-ttl-1: 1.5rem;
  --fs-ttl-2: 1.25rem;
  --fs-subttl-1: 1.125rem;
  --fs-subttl-2: 1rem;
  --fs-txt: 0.875rem;
  --fs-cpt: 0.75rem;

  // tablet
  @media only screen and (min-width: $tablet_min) {
    --fs-ttl-1: 2rem;
    --fs-ttl-2: 1.5rem;
    --fs-subttl-1: 1.25rem;
    --fs-subttl-2: 1.125rem;
    --fs-txt: 0.875rem;
    --fs-cpt: 0.8125rem;
  }

  // desktop
  @media only screen and (min-width: $desktop_min) {
    --fs-ttl-1: 3rem; // 48
    --fs-ttl-2: 2rem; // 32
    --fs-subttl-1: 1.5rem; // 24
    --fs-subttl-2: 1.25rem; // 20
    --fs-txt: 1rem; // 16
    --fs-cpt: 0.875rem; // 14

    --fs-nav: 1.375rem; // 22
  }

  --section-margin-bot: calc(1.3 * var(--fs-ttl-2));
  --ticket-corner-size: 1rem;
}

.text-weigth-bold {
  font-weight: var(--fw-bold);
}

.text-ttl-1 {
  font-size: var(--fs-ttl-1);
}

.text-subttl-2 {
  font-size: var(--fs-subttl-2);
}

.uppercase {
  text-transform: uppercase;
}

h1 {
  font-size: var(--fs-ttl-1);
  font-weight: var(--fw-bold);
  text-transform: uppercase;
}

h2 {
  font-size: var(--fs-ttl-2);
  font-weight: var(--fw-bold);
  text-transform: uppercase;
}

h3 {
  font-size: var(--fs-subttl-1);
  font-weight: var(--fw-regular);
}

h4 {
  font-size: var(--fs-subttl-2);
  font-weight: var(--fw-regular);
}

h5 {
  font-size: var(--fs-txt);
  font-weight: var(--fw-regular);
}

p, span {
  font-size: var(--fs-cpt);
  font-weight: var(--fw-regular);
}

.bg-primary {
  background-color: var(--clr-primary);
}

.bg-second-y {
  background-color: var(--clr-secondary-y);
}

.bg-second-w {
  background-color: var(--clr-secondary-w);
}

.bg-second-m {
  background-color: var(--clr-secondary-m);
}

.bg-accent-c {
  background-color: var(--clr-accent-c);
}

.bg-accent-g {
  background-color: var(--clr-accent-g);
}

.bg-accent-lg {
  background-color: var(--clr-accent-lg);
}

.bg-accent-o {
  background-color: var(--clr-accent-o);
}

.text-primary {
  color: var(--clr-primary);
}

.text-secondary-w {
  color: var(--clr-secondary-w);
}

.text-secondary-y {
  color: var(--clr-secondary-y);
}

.text-secondary-m {
  color: var(--clr-secondary-m);
}

.text-accent-o {
  color: var(--clr-accent-o);
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

.mw50 {
  min-width: 50%;
}

.h100 {
  height: 100%;
}

.grid {
  display: grid;
  --grid-gap: 1rem;
  --grid-temp-col: 1fr 1fr 1fr;

  gap: var(--grid-gap);
  grid-template-columns: var(--grid-temp-col);
}

.flex-column {
  flex-direction: column;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.flex-align-center {
  align-items: center;
}

.flex-align-flex-end {
  align-items: flex-end;
}

// elements visible only for screen readers
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  right: 0;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.max-width-container {
  max-width: 80rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin: 0 auto;
}

.super-container {
  padding: 1.25rem 1.25rem;
  max-width: 80rem;
  margin: 0 auto;

  --be-first-mar-top-abs: 3rem;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hover-color-secondary-y {
  &:hover {
    color: var(--clr-secondary-y);
  }
}

.hover-color-secondary-m {
  &:hover {
    color: var(--clr-secondary-m);
  }
}

.hover-bg-color-secondary-m {
  &:hover {
    background-color: var(--clr-secondary-m);
  }
}

.hover-bg-color-secondary-y {
  &:hover {
    background-color: var(--clr-secondary-y);
  }
}

.z-max {
  z-imdex: 9999;
}

.svg-button {
  display: block;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  background-size: 100%;
}

.clip-path-button {
  display: block;
  border: none;
  cursor: pointer;
  background-size: 100%;
}

.flex {
  display: flex;
  gap: var(--gap, 1.25rem);
}

.text-align-center {
  text-align: center;
}

section {
  margin-bottom: var(--section-margin-bot);
}

// for privacy_policy and terms_and_conditions page
#hosted {
  ul {
      list-style-type: square;
  }
  ul > li > ul {
      list-style-type: circle;
  }
  ul > li > ul > li > ul {
      list-style-type: square;
  }
  ol li {
      font-family: Arial ;
  }
  div[id]::before {
      content: '';
      display: block;
      height: 8rem;
      margin: -6rem 0 0 0;
  }
  ul {
    padding-left: 2em;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  [data-custom-class='body'] {
    padding: 20px;
  }
  * {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }
  body {
    margin: 0;
  }
  [data-custom-class='body'], [data-custom-class='body'] * {
    background: transparent !important;
  }
  [data-custom-class='title'], [data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
    font-weight: bold;
  }
  [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
    font-weight: bold;
  }
  [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
    font-weight: bold;
  }
  [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
  }
  [data-custom-class='body_text'], [data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
  }
  [data-custom-class='link'], [data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
  }
}
