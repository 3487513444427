.blogger-ticket {
  padding: 0.3rem;

  aspect-ratio: 1.47;
}

.blogger-ticket-content {
  padding: calc(1.2 * var(--fs-subttl-2));
}

.blogger-ticket-content__avatar {
  width: 20%;
  aspect-ratio: 1;
}

.blogger-ticket-content__gamer-name {
  margin-top: calc(0.5 * var(--fs-subttl-2));
}

.bt__link--height {
  height: 30%;
}

.bt--custom-corner {
  --ticket-corner-size: 15%;
}

.bt-shadow {
  background: linear-gradient(180deg, rgba(0,0,0,0.9052214635854342) 14%, rgba(0,0,0,0.13099177170868344) 50%, rgba(0,0,0,0.8996192226890756) 95%);
}
