.footer {
  padding: 1.5rem 0rem;
}

.footer__grid {
  --grid-temp-col: 1fr 1fr;
  justify-content: space-between;

  @media only screen and (max-width: 64rem) {
    --grid-temp-col: 1fr;
  }
}

.footer__logo {
  justify-content: center;

  @media only screen and (min-width: 64rem) {
    justify-content: flex-start;
  }
}

.footer__links-and-socials {
  margin-bottom: calc(-32px - var(--gap, 1.25rem));
}

.footer__socials {
  justify-content: center;

  @media only screen and (min-width: 64rem) {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
    justify-content: flex-end;
  }
}

.footer__trademark {
  justify-content: flex-start;

  @media only screen and (max-width: 64rem) {
    justify-content: center;
  }
}

.footer__links {
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 64rem) {
    min-width: 612px;
    flex-direction: row;
    justify-content: flex-end;
  }
}
