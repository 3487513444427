.expanding-ticket {
  width: 100%;

  padding: calc(0.7 * var(--ticket-corner-size));
}

.expanding-ticket__header {
  width: 90%;
}

.expanding-ticket__flex {
  flex-wrap: wrap-reverse;
}

.exp-g__icon {
  max-width: calc(1.7 * var(--fs-ttl-1));
}
