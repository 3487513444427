$content_width: 90%;

.be-first-section-container {
  margin-top: calc(-1 * (var(--be-first-mar-top-abs) + var(--section-margin-bot)));

  text-align: center;

  padding: 1.5rem 1.5rem;
}

.be-first-section__header {
  width: $content_width;
}

.be-first-section__header-separator {
  @media only screen and (min-width: 50rem) {
    width: 65%;
  }
}

.be-first-section__button {
  width: $content_width;

  @media only screen and (min-width: 64rem) {
    width: 33%;
  }
}

.be-first-section__button-inner-container {
  gap: 0.3rem;
  align-items: center;
}

.bf-butt-container {
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
}
