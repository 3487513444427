.blg__carousel--s {
  @media only screen and (min-width: 50.1rem) {
    display: none;
  }
}

.blg__carousel--m {
  @media only screen and (max-width: 50.1rem) {
    display: none;
  }

  @media only screen and (min-width: 80.1rem) {
    display: none;
  }
}

.blg__carousel--d {
  @media only screen and (max-width: 80.1rem) {
    display: none;
  }
}
