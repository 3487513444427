.trademark {
  gap: 0.3em;
  flex-wrap: wrap;
  padding: 0.5em 0;

  @media only screen and (max-width: 30rem) {
    justify-content: center;
  }
}

.trademark__text-copyright {
  font-size: calc(var(--fs-subttl-2) / 2 + 0.25rem);
  white-space: nowrap;
}
