.section__title {
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 50rem) {
    text-align: center;
  }
}

.section__content-container {
  @media only screen and (min-width: 50rem) {
    display: flex;
    gap: 2em;
  }
}
